import {  Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, GridColumn, GridRow, Image } from 'semantic-ui-react';
import { useStore } from '../../../api/stores/store';
import * as Yup from 'yup';
import FileInput from '../../UIItems/FileInput';
import ServerMsgBar from '../../UIItems/ServerMsgBar';
import { ServerResult  } from '../../../api/models/common';

export default observer(function SysUpdate() {
    const {resourcesStore} = useStore();
    const formData = new FormData();
    const [updateStatus,setUpdateStatus] = useState( { isSuccess:false, msg:''});    
    const [ uploadingNow, setUploadingNow] = useState(false);
    const [ deployingNow, setDeployingNow] = useState(false);
    const [uploadState, setUploadState] =  useState<ServerResult  | undefined  >(undefined);
    const [deployState, setDeployState] =  useState<ServerResult  | undefined  >(undefined);
    const [ systemUpdated, setSystemUpdated] = useState(false);
    const refResponseUpdStatus = useRef<HTMLDivElement>(null);
    function useOnClickOutside(
        ref: React.RefObject<HTMLElement>,
        handler: (event: MouseEvent) => void
      ) {
        useEffect(() => {
          const handleClick = (event: MouseEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
              return;
            }
            handler(event);
          };
          document.addEventListener('mousedown', handleClick);
          return;
        }, [ref, handler]);
      }
      useOnClickOutside(refResponseUpdStatus, () => hideResponseState());
    function hideResponseState()
    {
        setUploadState(undefined)
        setDeployState(undefined)
    }

    return (
        <Formik
            initialValues={{
            fileType: '0',
            fileVersion: '0',
            insertDate: '',
            activeVersion: '',
            fileName:'',
            file:null,
            error: null}}

            onSubmit={(values) => {
                if (values.file)
                    {
                        setUploadingNow(true);
                        formData.append('file', values.file);
                        formData.append('fileType', values.fileType);
                        formData.append('fileVersion',values.fileVersion);
                        formData.append('fileName',values.fileName);

                        resourcesStore.uploadSysUpdate(formData)
                            .then(_res => {
                                  setUploadState( {
                                    isSuccess: true,
                                    msg: "File successfully uploaded",
                                  });
                                  setUploadingNow(false);
                            })
                            .catch(error => {
                              setUploadState( {
                                isSuccess: false,
                                msg: 'Error on Upload:' + (error.response.status == 401)?"Upload not permitted":error

                              });
                              setUploadingNow(false);
                            })
                        }
                    else
                    setUploadState( {
                        isSuccess: false,
                        msg: "Not  selected file for uploading",
                      });
                  
            } }
            validationSchema={Yup.object().shape({
                file: Yup.mixed().required('File is required'),
            })} >

            {({  handleSubmit,setFieldValue,values,isValid}) => {
            const hendlFileChange = function (uploadFile :File){
                    setFieldValue("file", uploadFile);
                    setFieldValue("fileName", uploadFile.name);
            }

            function handlRunSysUpdate() {
                    setDeployingNow(true);
                    resourcesStore.runSysUpdate()
                            .then(_res => {
                                setDeployState({
                                    isSuccess: true,
                                    msg: "Backups monitoring system successfully updated."
                                })
                                setDeployingNow(false);
                                setSystemUpdated(true);
                            })
                            .catch(error => {
                                setDeployState({
                                    isSuccess: false,
                                    msg: 'Error on Deploy:' + (error.response.status == 401)?"Deployment  not permitted":error
                            })
                            setDeployingNow(false);
                            setSystemUpdated(false);
                        })   
                 }
            return (
                    <Form className='ui form error internalForm '   onSubmit={handleSubmit} autoComplete='off'>
                                <Grid >
                                <GridRow className="formFieldRow">
                                                <GridColumn>
                                                    <Image
                                                    src="/img/upload_2.svg"
                                                    className="formSubTitleImg"
                                                    />
                                                    <span className="formSubTitle">Upload System Update</span>
                                                    <span className="subLabel">(Zip format)</span>
                                                </GridColumn>
                                        </GridRow>
                                        <GridRow className="formFieldRow">      
                                                 <GridColumn className='file-inputBox'>
                                                 <Button  loading={uploadingNow}  disabled={values.file === null || uploadingNow }  type='submit' className='applyBtn'>
                                                 Upload
                                                            <Image src="/img/upload5.svg" className='btnImg' />
                                                    </Button>
                                                    <FileInput name="file" onFileChanged={hendlFileChange}/>
                                            </GridColumn>
                                    </GridRow>
                                    <GridRow className="formFieldRow">
                                                <GridColumn>
                                                    <Image
                                                    src="/img/autorenew.svg"
                                                    className="formSubTitleImg"
                                                    />
                                                    <span className="formSubTitle">Update System</span>
                                                    <span className="subLabel">Description</span>
                                                </GridColumn>
                                        </GridRow>
                                        <GridRow className="formFieldRow">             
                                                 <GridColumn className='file-inputBox'>
                                                 <Button    type="button"  className='applyBtn  sysAction' loading={deployingNow} disabled={deployingNow}   
                                                 onClick={handlRunSysUpdate} >Deploy </Button>
                                                    {systemUpdated && (
                                                                <a className='activeLink' onClick={() => window.location.reload()}>Refresh to use the new version</a>
                                                    )}
                                            </GridColumn>
                                    </GridRow> 
                                    <div ref={refResponseUpdStatus}>                              
                                    {uploadState && (
                                            <ServerMsgBar runActionResult={uploadState} />
                                    )}
                                     {deployState && (
                                            <ServerMsgBar runActionResult={deployState} />
                                    )}</div>   
                                </Grid>
                                </Form> ); }}
                </Formik >
                 
    );})